import lazyLoadImages from './components/lazy';
import { onlajnySticker } from './sticker';

onlajnySticker();

const typography = document.querySelector('.typography');

// init lazy load images
lazyLoadImages();

// add typography functions
if (typography) {
    import('./components/typography').then((script) => {
        script.typography();
    });
}

// js select
const selects = document.querySelectorAll('select.js-select');
if (selects.length) {
    selects.forEach((select) => {
        select.addEventListener('change', () => {
            window.location = select.value;
        });
    });
}

// js radio
const radioButtons = document.querySelectorAll('input[type="radio"].js-radio');
if (radioButtons.length) {
    radioButtons.forEach((radio) => {
        radio.addEventListener('change', () => {
            window.location = radio.value;
        });
    });
}

// add scroll listener
let lastScrollY = 0;
window.addEventListener('scroll', () => {
    const header = document.querySelector('header');

    // fixed menu fix based of body padding because of league panel at the very top
    document.body.classList.toggle('scrolled', window.scrollY > 40);

    if (window.scrollY > 500 && !header.classList.contains('menu-shown') && !header.classList.contains('aside-shown')) {
        header.style.transform = 'translateY(-150%)';
    } else {
        header.style.transform = 'translateY(0)';
    }
    if (
        lastScrollY > window.scrollY &&
        window.scrollY > 500 &&
        !header.classList.contains('menu-shown') &&
        !header.classList.contains('aside-shown')
    ) {
        header.style.transform = 'translateY(0)';
    }
    lastScrollY = window.scrollY;
});
