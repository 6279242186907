export const onlajnySticker = () => {
    const sticker = document.querySelector('.sticker[data-id]');
    if (sticker) {
        const id = sticker.dataset.id;
        if (id && !stickerHasBeenShown(id)) {
            sticker.classList.add('show');
            sticker.querySelector('.sticker-close').addEventListener('click', () => {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                date = date.toUTCString();
                document.cookie = `onlajny_sticker_id=${id}; expires=${date}; path=/`;
                sticker.classList.remove('show');
            });
        }
    }

    // Returns true/false if sticker id is stored in a cookie
    function stickerHasBeenShown(id) {
        return document.cookie.split(';').some((item) => item.includes(`onlajny_sticker_id=${id}`)) ? true : false;
    }
};
